/***************GLOBAL***********************/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
html,
body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  background: transparent !important;
  font-weight: 400;
  font-family: "Fira Sans", sans-serif;
}

#root {
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr 25px auto;
  grid-template-columns: auto;
}
/**********HEADER**********************/
.header {
  padding: 1rem;
}

.navbar {
  padding-right: 1rem;
  padding-left: 1rem;
  list-style-type: none;
  background: transparent !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.navbar li a {
  display: block;
  padding: 5px;
  text-decoration: none;
}
.nav-logo {
  font-weight: bold;
  flex: 1;
  order: 0;
  padding: 7.5px 10px 7.5px 0;
}
.nav-item {
  text-align: center;
  display: none;
  position: relative;
  display: block;
  width: auto;
}
.nav-item-bold {
  order: 1;
  color: rgba(0, 0, 0, 0.9);
  font-size: 1.25rem;
}

.nav-item-lite {
  order: 2;
  color: rgba(0, 0, 0, 0.5);
}
.active .nav-item {
  display: block;
}

/***********SWITCH*********************/
.main {
  padding: 1rem;
}

.table {
  width: min-content;
  height: min-content;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  max-width: 70%;
  width: min-content;
  overflow: scroll;
}
.innerContainerAutoSize {
  margin: 0px auto;
  background: transparent !important;
  transition: all 0.5s;
  max-width: 70%;
  min-width: 30%;
  width: min-content;
  height: 100%;
}
.innerContainer {
  overflow: auto;
  background: transparent !important;
  padding: 40px 55px 45px 55px;
  transition: all 0.3s;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 50%;
  min-width: 30%;
  height: 100%;
  align-content: center;
}
.fullWidth {
  width: 100% !important;
  padding: 0 !important;
  margin-left: 2rem;
  margin-right: 2rem;
}
.subNav {
  overflow: auto;
  margin-left: auto;
  margin-right: auto;
  width: max-content;
  text-align: center;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.subNav a {
  display: block;
  padding: 5px;
  text-decoration: none;
  border: none;
  background-color: transparent;
  outline: none;
}
.subNav-item {
  text-align: center;
  display: none;
  position: relative;
  display: block;
  width: auto;
}
.subNav-item-bold {
  color: rgba(0, 0, 0, 0.9);
}

.subNav-item-lite {
  color: rgba(0, 0, 0, 0.5);
}
.active .subNav-item {
  display: block;
}
.entryForm {
  display: block;
}
.select {
  display: block;
  width: 100%;
  padding: 0.375rem;
  border: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background: white;
}
.selectOption {
  color: #495057;
}
.addLabel {
  margin-top: 1rem;
  color: #495057;
}
.textArea {
  width: 100%;
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
}
/****************FOOTER*******************/
footer {
  justify-content: center;
  padding-top: 5px;

  background-color: #000000;
  color: white;
  text-align: center;
}
.footerVersion {
  margin-bottom: 0rem !important;
}
/*************GENERAL**************************/
.errorMessage {
  color: #dc3545;
}

.timezone-picker {
  overflow: true;
  width: 100%;
  padding: 9px 12px;
  display: inline-block;
  font: 13px sans-serif;
  position: relative;
}

.timezone-picker-textfield input {
  width: 100%;
  padding: 9px 12px;
  font: inherit;
  box-sizing: border-box;
  outline: 0;
  background: #fff;
  border-radius: 2px;
}
.timezone-picker-list {
  top: 100%;
  left: 0;
  right: 0;
  max-height: 75px;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  border: 1px solid #e6ebec;
  margin-top: -1px;
  border-radius: 0 0 3px 3px;
  display: none;
}
.timezone-picker-list-rel {
  position: relative;
}
.timezone-picker-list-abs {
  position: absolute;
  background-color: #fff;
  z-index: 2000;
  opacity: 0.99;
}
.timezone-picker-list-item {
  padding: 5px 12px;
}
.timezone-picker-list-item-active {
  background: #ececec;
  cursor: pointer;
}
.timezone-picker-open .timezone-picker-list {
  display: block;
}
.stripeCardSetupForm {
  width: 450px;
  max-width: calc(100% - 20px);
  margin: 20px 0;
  animation: ReactStripeDemo-fade 200ms ease-out;
  position: relative;
}
/* ########################   General ######################### */
.keyValue {
  display: grid;
  width: fit-content;
  grid-template-columns: auto auto;
  grid-template-areas: "key   value";
}
.key {
  grid-area: key;
  text-align: left;
  width: fit-content;
}
.value {
  grid-area: value;
  text-align: center;
  align-items: center;
  padding-left: 1rem;
  align-self: center;
}

.biPanel {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-areas: "pl   pr";
  width: 100%;
}
.triPanel {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-areas: "pl pc  pr";
  width: 100%;
}
.paneLeft {
  grid-area: pl;
  vertical-align: top;
  text-align: left;
}
.paneRight {
  grid-area: pr;
  vertical-align: top;
  text-align: left;
}
.paneCenter {
  grid-area: pc;
  vertical-align: top;
  text-align: left;
}
.shadow:hover {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}
.shadow:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.fitContent {
  width: fit-content;
}
.quadCard {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-areas:
    "tl   tr"
    "bl   br";
  margin-bottom: 1rem;
  border-radius: 5px;
  padding: 5px 5px 5px 5px;
  transition: all 0.5s;
  max-width: inherit;
}
.tl {
  grid-area: tl;
  vertical-align: top;
  text-align: left;
  margin-bottom: 0.5rem;
}

.tr {
  grid-area: tr;
  vertical-align: top;
  text-align: right;
  margin-bottom: 0.5rem;
}
.bl {
  grid-area: bl;
  vertical-align: bottom;
  text-align: left;
  margin-top: 0.5rem;
}
.br {
  grid-area: br;
  vertical-align: bottom;
  text-align: right;
  margin-top: 0.5rem;
}

.meta {
  font-size: small;
  color: gray;
}
.metaButton {
  font-size: small;
  color: gray;
  border: 0;
  background: transparent !important;
  margin-left: 0.5rem;
  width: fit-content;
  height: fit-content;
}

.info {
  font-size: small;
  color: 000;
}
.deleteButton {
  color: red;
  font-size: x-small;
  border: 0;
  background: transparent !important;
  margin-left: auto;
  width: fit-content;
  height: fit-content;
}
.deleteButton:disabled {
  color: grey !important;
}

.submitButton {
  color: #fff;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  background-color: #007bff;
  border-color: #007bff;
  text-transform: none;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  max-width: 20rem;
}
.submitButton:disabled {
  background-color: grey !important;
  border-color: grey !important;
}
.submitButton:focus {
  outline: none;
  box-shadow: none;
}
.submitButtonSlim {
  color: #fff;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  background-color: #007bff;
  border-color: #007bff;
  text-transform: none;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.1rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.submitButtonSlim:disabled {
  background-color: grey !important;
  border-color: grey !important;
}
.navButton {
  background-color: #fff;
  border-color: #fff;
  text-transform: none;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0px solid transparent;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.navButton:disabled {
  color: grey !important;
}
.navButton:focus {
  outline: none;
  box-shadow: none;
}
.navButton:hover {
  color: #000;
}
.textButton {
  background-color: #fff;
  border-color: #fff;
  color: #004fa3;
  text-transform: none;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0px solid transparent;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.contentContainer {
  overflow: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.textButton:disabled {
  color: grey !important;
}
.textButton:focus {
  outline: none;
  box-shadow: none;
}
.textButton:hover {
  color: #000;
}

.bold {
  font-weight: bold;
}
.happyText {
  color: green;
}
.sadText {
  color: #dc3545;
}
.paddingTopLite {
  padding-top: 0.5rem;
}
.paddingTop {
  padding-top: 1rem;
}

.paddingLeft {
  padding-left: 1rem;
}
.paddingRight {
  padding-right: 1rem;
}
.paddingBottom {
  padding-bottom: 1rem;
}
.marginTopXLite {
  margin-top: 0.25rem;
}
.marginTop {
  margin-top: 1rem;
}
.marginLeft {
  margin-left: 1rem;
}
.marginRight {
  margin-right: 1rem;
}

.vertCenterInput {
  padding: 0.75rem 0.75rem !important;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.textCenter {
  text-align: center;
}
.smallInput:hover {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.3);
}
.smallInput {
  font-size: small;
  border: 0;
  padding: 0rem;
  background: transparent !important;
  width: fit-content;
  height: fit-content;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  margin-top: 0.1rem;
}
.aligntRight {
  float: right;
}
.nav-logo {
  font-weight: bold;
  flex: 1;
  order: 0;
  padding: 7.5px 10px 7.5px 0;
}
.noVerticalMargin {
  margin: 0 !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
.headerLogo {
  max-height: 48px;
}
.headerTextLogo {
  font-size: xx-large;
  font-weight: 500;
}
